import * as Api from '../../api';
import { createSlice } from '@reduxjs/toolkit';
import { showLoader, hideLoader } from '../Loader';
import { showErrorNotification, showSuccessNotification } from '../Notification';


export const initialState = {
    unlockAccountStatus: "",
    tokenDetails: [],
    setPinStatus: ""
};

export const rsaServiceSlice = createSlice({
    name: 'rsaServiceDetails',
    initialState,
    reducers: {
        setUnlockAccountStatus: (state, action) => {
            return {
                ...state,
                unlockAccountStatus: action.payload,
            }
        },
        setOneTimeToken: (state, action) => {
            return {
                ...state,
                tokenDetails: action.payload,
            }
        },
        setNewPin: (state, action) => {
            return {
                ...state,
                setPinStatus: action.payload,
            }
        }
    },
});

export const { setUnlockAccountStatus, setOneTimeToken, setNewPin } = rsaServiceSlice.actions

export const rsaServiceSelector = (state) => state.rsaServiceDetails;

export default rsaServiceSlice.reducer;

export const unlockAccount = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Post(dispatch, 'api/v1/rsa/unlock', null, null)
                .then(data => {
                    dispatch(setUnlockAccountStatus(data));
                    dispatch(showSuccessNotification("Unlocked RSA account successfully."));
                    dispatch(hideLoader());
                })
                .catch((error) => {
                    dispatch(hideLoader());
                });
        });
    };
}

export const getOneTimeToken = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Get(dispatch, 'api/v1/rsa/getonetimetoken', null, null)
                .then(data => {
                    dispatch(setOneTimeToken(data));
                    dispatch(showSuccessNotification("One Time Token generated successfully."));
                    dispatch(hideLoader());
                })
                .catch((error) => {
                    dispatch(hideLoader());
                });

        });
    };
}

export const setNewPinCall = (newPin, confirmNewPin) => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Post(dispatch, 'api/v1/rsa/setnewpin', null, { 'newPin': newPin, 'confirmNewPin': confirmNewPin })
                .then(data => {
                    dispatch(setNewPin(data));
                    dispatch(showSuccessNotification("New pin successfully setup"));
                    dispatch(hideLoader());
                })
                .catch((error) => {
                    dispatch(hideLoader());
                });

        });
    };
}