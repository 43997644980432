import * as React from 'react';
import { Popover, Button} from '@mui/material';
import '../LogOff/LogOff.scss';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import * as Api from '../../api'
import { useDispatch } from 'react-redux';
import { logOff } from '../../redux/PasswordReset/Slice';
import { setItem } from '../../util/localStorage';

export default function LogOff() {
    let [user, setUser] = React.useState({ firstName: '', lastName: '', empId: '', email: '' })
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isActive = window.localStorage.getItem('isActive');

    React.useEffect(() => {
        if (isActive)
            Api.Get(dispatch,'api/v1/userProfile/userInfo', null, null)
                .then(data => {
                    setUser(data);
                    setItem('userFirstName',data.firstName);
                    setItem('userLastName',data.lastName);
                    setItem('empId', data.empId);
                    setItem('uId', data.uid);
                }).catch((error) => {
                    user = {}
                });
    }, []);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logout = () => {
        setAnchorEl(null);
        dispatch(logOff());
    };

    return (
        <>
            {isActive &&
                <div>
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        aria-label='btnUser'
                        aria-describedby='logInInfo'

                    >
                        <Avatar sx={{ width: 32, height: 32 }}><PersonOutlineIcon /></Avatar>
                    </IconButton>
                    <Popover
                        id='logInInfo'
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => handleClose()}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className="user-name" role="text"><PersonOutlineIcon aria-label='person-icon' /> {user.firstName} {user.lastName}</div>
                        <div className="user-details">
                            <div><span>Employee Id:</span> <span className="loginid">{user.empId}</span></div>
                            <div className="emailId">{user.email}</div>
                        </div>
                        <Button onClick={() => logout()}><LogoutIcon aria-label='logout-icon' /> Logout</Button>
                    </Popover>
                </div>
            }
        </>
    );
}
